@import url(../../vendor/fonts.css);

.navigation {
  background-color: #073042;
  position: relative;
}

.navigation__content {
  display: flex;
  width: calc(100vw - 550px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: visibility, 900ms ease;
}

.navigation__content:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  align-self: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation__content_opened {
  display: flex;
  opacity: 1;
  z-index: 4;
  visibility: visible;
}

@media screen and (max-width: 770px) {
  .navigation__content {
    padding: 18px 30px 18px 30px;
  }
}

.navigation__menu-icon {
  width: 44px;
  height: 44px;
  background-image: url(../../images/menu.svg);
  position: absolute;
  top: 15px;
  right: 30px;
  background-position: center;
  border: none;
  background-size: contain;
  display: none;
  background-color: #FAFAFA;
}

@media screen and (max-width: 770px) {
  .navigation__menu-icon {
    display: inline;
  }
}

@media screen and (max-width: 420px) {
  .navigation__menu-icon {
    width: 40px;
    height: 43px;
    right: 14px;
  }
}

.navigation__block {
  display: none;
  width: 520px;
  height: 100vh;
  background: #FAFAFA;
  position: fixed;
  top: 0;
  right: 0;
  transition: right .5s;
  align-items: center;
  padding-top: 159px;
}

@media screen and (max-width: 770px) {
  .navigation__block {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
}

@media screen and (max-width: 525px) {
  .navigation__block {
    width: 100vw;
    padding-top: 144px;
  }
}

.navigation__menu-icon_opened {
  background-image: url(../../images/exit-menu.svg);
  z-index: 5;
  position: fixed;
}

@media screen and (max-width: 420px) {
  .navigation__menu-icon_opened {
    width: 22px;
    height: 22px;
  }
}

.navigation__menu-icon:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 1s;
}

.navigation__link {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
}

.navigation__link:first-child {
  padding-bottom: 28px;
}

@media screen and (max-width: 500px) {
  .navigation__link:first-child {
    padding-bottom: 24px;
  }
}

.navigation__link:last-of-type {
  padding-top: 8px;
}

.navigation__link:nth-child(2) {
  border-bottom: 2px solid black;
  padding-bottom: 4px;
}

@media screen and (max-width: 500px) {
  .navigation__link:nth-child(2) {
    padding-bottom: 7px;
  }
}

.navigation__link:nth-child(3) {
  padding-top: 22px;
}

@media screen and (max-width: 500px) {
  .navigation__link:nth-child(3) {
    padding-top: 15px;
  }
}

.navigation__profile-link {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: #F4F4F4;
  border-radius: 20px;
  padding: 8px 20px 8px 20px;
  margin-top: calc(100vh - 406px);
}

@media screen and (max-width: 500px) {
  .navigation__profile-link {
    margin-top: calc(100vh - 336px);
  }
}

/*@media screen and (max-width: 525px) {
  .navigation__link:nth-child(-n+2) {
    padding-bottom: 24px;
  }
}*/
