@font-face {
  src: url(./Inter/Inter-Black.woff) format('woff'),
       url(./Inter/Inter-Black.woff2) format('woff2');
  font-family: 'Inter';
  font-weight: 900;
}

@font-face {
  src: url(./Inter/Inter-Regular.woff) format('woff'),
       url(./Inter/Inter-Regular.woff2) format('woff2');
  font-family: 'Inter';
  font-weight: 400;
}

@font-face {
  src: url(./Inter/Inter-Medium.woff) format('woff'),
       url(./Inter/Inter-Medium.woff2) format('woff2');
  font-family: 'Inter';
  font-weight: 500;
}

