@import url(../../vendor/fonts.css);

.header {
  background-color: #FAFAFA;
  position: relative;
}

.header__content {
  max-width: 1140px;
  padding: 18px 70px 18px 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #FAFAFA;
}

@media screen and (max-width: 770px) {
  .header__content {
    padding: 21px 30px 21px 30px;
  }
}

.header__logo {
  width: 38px;
  height: 38px;
}

.header__logo:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 1s;
}

.header__right-block {
   justify-items: center;
   display: flex;
   align-items: center;
}

.header__right-block-login {
  justify-items: center;
  display: flex;
  align-items: center;
}

.header__left-block-login {
 justify-items: center;
 display: flex;
 align-items: center;
}

@media screen and (max-width: 770px) {
  .header__film-link {
    display: none;
  }
}

@media screen and (max-width: 770px) {
  .header__account-link {
    display: none;
  }
}

.header__link {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
}

.header__account-link {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(0, 0, 0);
  background-color: #F4F4F4;
  border-radius: 20px;
  padding: 8px 20px;
}

.header__film-link {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: rgb(0, 0, 0);
}

.header__film-link:last-child {
  font-weight: 400;
  padding-left: 16px;
}

.header__film-link:nth-child(2) {
  padding-left: 44px;
}

.header__link:first-child {
  padding-right: 30px;
}

@media screen and (max-width: 520px) {
  .header__link:first-child {
    padding-right: 14px;
  }
}

.header__login-link {
  background-color: #3456F3;
  border-radius: 3px;
  padding: 8px 20px 8px 20px;
  color: rgb(255, 255, 255);
}

.header__link:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 1s;
}

.header__icon {
  width: 26px;
  height: 30px;
  background-color: #F5F5F5;
  padding: 8px 7.3px 8px 7.3px;
  border-radius: 6px;
  margin-left: 17px;
}

@media screen and (max-width: 520px) {
  .header__content {
    padding: 18px 14px 18px 14px;
  }
}

@media screen and (max-width: 500px) {
  .header__link {
    font-size: 10px;
    line-height: 16px;
    padding-right: 14px;
  }
}