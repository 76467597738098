@import url(../../../vendor/fonts.css);

.techs__content {
  background-color: #F5F5F5;

}

.techs__content-inside {
  justify-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  padding: 100px 70px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.techs__title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  padding-bottom: 23px;
  max-width: 1140px;
}

.techs__main-title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin: 0;
  padding-bottom: 26px;
  text-align: center;
  padding-top: 90px;
}

.techs__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
  align-self: center;
}

.techs__text-background {
  background-color: #E8E8E8;
  border-radius: 10px;
  min-height: 60px;
  display: grid;
  align-content: center;
  max-width: 90px;
}

.techs__column {
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
  max-width: 1280px;
}

.techs__grid-line {
  max-width: 1140;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, 90px);
  grid-template-rows: repeat(1, 60px);
  gap: 10px;
  text-align: center;
  padding-top: 100px;
  justify-content: center;
}

@media screen and (max-width: 770px) {
  .techs__content-inside {
    padding: 90px 50px;
  }
}

@media screen and (max-width: 770px) {
  .techs__title {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 770px) {
  .techs__main-title {
    padding-bottom: 22px;
    padding-top: 80px;
  }
}

@media screen and (max-width: 770px) {
  .techs__text {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 770px) {
  .techs__grid-line {
    grid-template-columns: repeat(auto-fit, minmax(10px, 84px));
    grid-template-rows: repeat(auto-fit, 57px);
    padding-top: 83px;
  }
}

@media screen and (max-width: 650px) {
  .techs__main-title {
    font-size: 30px;
    line-height: 36px;
    padding-top: 60px;
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 500px) {
  .techs__content-inside {
    padding: 70px 18px;
  }
}

@media screen and (max-width: 500px) {
  .techs__text {
    font-size: 11px;
    line-height: 16px;
  }
}
