.searchForm {
  display: flex;
  flex-direction: column;
  padding: 70px 70px 10px 70px;
  box-sizing: border-box;
  align-items: stretch;
  align-items: center;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .searchForm {
    padding: 60px 30px 0px 30px;
  }
}

@media screen and (max-width: 770px) {
  .searchForm {
    padding: 80px 30px 10px 30px;
  }
}

@media screen and (max-width: 500px) {
  .searchForm {
    padding: 60px 10px 0px 10px;
  }
}

.searchForm__form {
  max-width: 1140x;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  padding-bottom: 84px;
  margin: 0;
  align-items: baseline;
  border-bottom: 1px solid #E8E8E8;
}

@media screen and (max-width: 770px) {
  .searchForm__form {
    grid-template-columns: auto;
    padding-bottom: 109px;
  }
}

@media screen and (max-width: 500px) {
  .searchForm__form {
    grid-template-columns: auto;
    grid-template-rows: 72px 65px;
    padding-bottom: 44px;
  }
}

.searchForm__input-container {
  grid-column: 1/1;
  grid-row: 1/1;
  flex-direction: row;
  justify-content: stretch;
  position: relative;
  display: grid;
  grid-template-columns: 1 auto;  
  max-width: 1077px;
}

.searchForm__input {
  border-style: none;
  background: #F4F4F4;
  border-radius: 9px;
  max-width: 1024px;
  min-width: 1024px;
  height: 72px;
  z-index: 1;
  grid-column: 1/1;
  grid-row: 1/1;
  padding: 0 0 0 53px;
}


.searchForm__input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #FAFAFA inset !important;
}

.searchForm__input:-webkit-autofill, 
.searchForm__input:-webkit-autofill:active, 
.searchForm__input:-webkit-autofill:focus, 
.searchForm__input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px #FAFAFA inset;
}

.searchForm__input:focus-visible {
  outline: #FAFAFA auto 0px;
}

.searchForm__input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(#FAFAFA, rgba(70, 90, 126, 0.4)) !important;
  color: fieldtext !important;
}

@media screen and (max-width: 1140px) {
  .searchForm__input {
    min-width: calc(100vw - 193px);
  }
}

@media screen and (max-width: 770px) {
  .searchForm__input {
    min-width: calc(100vw - 113px);
  }
}

@media screen and (max-width: 500px) {
  .searchForm__input {
    padding: 0 0 0 19px;
    min-width: calc(100vw - 39px);
  }
}

.searchForm__find-button {
  width: 64px;
  height: 34px;
  background-image: url(../../../images/arrow-find.svg);
  background-size: no-repeat;
  background-position: center;
  border: none;
  background-position: right;
  z-index: 2;
  position: absolute;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  align-self: center;
  justify-self: end;
  margin-right: 226px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  border-right: 1px solid #CBCBCB;
  padding: 0;
  background-color: rgb(210 218 223 / 0%);
}

@media screen and (max-width: 770px) {
  .searchForm__find-button {
    margin-right: 187px;
    width: 53px;
  }
}

@media screen and (max-width: 500px) {
  .searchForm__find-button {
    margin-right: 18px;
    border-right: none;
  }
}

.searchForm__invisible-checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
	opacity: 0;
	display: block;
}

.searchForm__find-button:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.searchForm__tumb-container {
	display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  max-width: 167px;
  max-height: 48px;
  place-self: center;
  grid-column: 1/1;
  grid-row: 1/1;
  justify-self: end;
  margin-right: 30px;
}

@media screen and (max-width: 770px) {
  .searchForm__tumb-container {
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .searchForm__tumb-container {
    grid-row: 2/2;
    align-self: end;
    justify-self: center;
    margin-right: 0px;
  }
}

.searchForm__visible-checkbox {
  background-image: url(../../../images/smalltumb.svg);
  height: 20px;
  z-index: 3;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 167px;
  text-align: end;
}

.searchForm__visible-checkbox1 {
  background-image: url(../../../images/smalltumboff.svg);
  height: 20px;
  z-index: 3;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 167px;
  text-align: end;
}

@media screen and (max-width: 770px) {
  .searchForm__visible-checkbox {
    width: 148px;
  }
}

@media screen and (max-width: 770px) {
  .searchForm__visible-checkbox1 {
    width: 148px;
    top: 0;
  }
}

.searchForm__visible-checkbox:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.searchForm__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
}

@media screen and (max-width: 770px) {
  .searchForm__text {
    font-size: 11px;
  }
}

.searchForm__img {
  color: #C4C4C4;
  height: 34px;
  width: 34px;
  z-index: 2;
  position: absolute;
  margin-left: 19px;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  align-self: center;
  justify-self: start;
}

@media screen and (max-width: 500px) {
  .searchForm__img {
    display: none;
  }
}