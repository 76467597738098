.savedMoviesCard__card {
  max-width: 364px;
  height: 271px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  object-fit: cover;
  margin: 0;
  position: relative;
}

@media screen and (max-width: 1279px) {
  .savedMoviesCard__card {
    max-width: 339px;
    height: 258px;
  }
}

@media screen and (max-width: 400px) {
  .savedMoviesCard__card {
    max-width: 300px;
    height: 248px;
  }
}

.savedMoviesCard__image {
  width: 364px;
  height: 203px;
  margin: 0;
  background-size: contain;
}

@media screen and (max-width: 770px) {
  .savedMoviesCard__image {
    width: 339px;
    height: 190px;
  }
}

@media screen and (max-width: 400px) {
  .savedMoviesCard__image {
    width: 300px;
    height: 168px;
  }
}

.savedMoviesCard__title-container {
  max-height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
  border-bottom: 1px solid #E8E8E8;
}

.savedMoviesCard__text-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 770px) {
  .savedMoviesCard__title-container {
    max-width: 339px;
  }
}

@media screen and (max-width: 400px) {
  .savedMoviesCard__title-container {
    max-width: 300px;
  }
}
  
.savedMoviesCard__title {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: start;
  color: #000000;
  max-width: 246px;
  margin: 0;
  padding: 14px 26px 9px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
.savedMoviesCard__duration {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #A0A0A0;
  padding: 0 0 14px 14px;
  margin: 0;
}

.savedMoviesCard__del-button {
  width: 30px;
  height: 30px;
  background-image: url(../../../images/button-del.svg);
  background-position: center;
  border: none;
  background-size: contain;
  border-radius: 50%;
  margin-top: 10px;
  margin: auto 14px auto 0;
  position: absolute;
  top: 19px;
  z-index: 3;
  right: 0;
}

.savedMoviesCard__card:hover .savedMoviesCard__del-button {
  cursor: pointer;
  transition: 1s;
  opacity: 0.5;
}

.savedMoviesCard__add-button-active {
  background-image: url('../../../images/heart-pink.svg');
}

.savedMoviesCardList__text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 0;
}

.savedMoviesCardList__button_disabled {
  display: none;
}

.savedMoviesCard__link {
  text-decoration: none;
}
