@import url(../../../vendor/fonts.css);

.aboutMe__content {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 110px 70px 100px 70px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.aboutMe__title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  padding-bottom: 23px;
}

.aboutMe__name-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  margin: 0;
  padding-bottom: 18px;
}

.aboutMe__status-text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 26px;
}

.aboutMe__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  max-width: 600px;
  padding-right: 49px;
}

.aboutMe__column {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  justify-content: space-between;
}

.aboutMe__text-block {
  display: flex;
  flex-direction: row;
  padding-top: 66px;
  border-top: 1px solid black;
  justify-content: space-between;
}

.aboutMe__photo {
  width: 270px;
  height: 327px;
  border-radius: 10px;
}

.aboutMe__link {
  text-decoration: none;
  color: black;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.aboutMe__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

@media screen and (max-width: 770px) {
  .aboutMe__content {
    padding: 90px 50px 90px 50px;
  }
}

@media screen and (max-width: 770px) {
  .aboutMe__photo {
    width: 255px;
    height: 307px;
  }
}

@media screen and (max-width: 770px) {
  .aboutMe__text {
    font-size: 14px;
    line-height: 22px;
    max-width: 364px;
  }
}

@media screen and (max-width: 770px) {
  .aboutMe__text {
    font-size: 14px;
    line-height: 22px;
    max-width: 364px;
  }
}

@media screen and (max-width: 770px) {
  .aboutMe__status-text {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 770px) {
  .aboutMe__name-text {
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__text-block {
    flex-direction: column-reverse;
    padding-top: 60px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__photo {
    padding-bottom: 40px;
    align-self: center;
    width: 292px;
    height: 352px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__content {
    padding: 70px 14px 70px 14px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__text {
    padding-right: 0;
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__status-text {
    font-size: 11px;
    line-height: 16px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__name-text {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__title {
    padding-bottom: 28px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 650px) {
  .aboutMe__link {
    padding-top: 40px;
  }
}