@import url(../../../vendor/fonts.css);

.NavTab__content {
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  min-height: 66px;
}

.navTab__block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  padding: 0px;
  gap: 10px;
  justify-content: space-between;
  min-height: 36px;
  min-width: 96px;
  background-color: #F5F5F5;
  padding-bottom: 30px;
}

.navTab__link-point {
  list-style-type: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  max-width: 276px;
}

.navTab__link {
  text-decoration: none;
  color: rgb(0, 0, 0);
  background-color: #E8E8E8;
  margin: 0;
  border-radius: 10px;
  padding: 10px 17px;
  min-height: 36px;
  min-width: 96px;
}

.navTab__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

@media screen and (max-width: 500px) {
  .navTab__block {
    max-width: 258px;
    gap: 6px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .navTab__link-point {
    font-size: 10px;
    line-height: 16px;
  }
}

@media screen and (max-width: 500px) {
  .navTab__link {
    font-size: 10px;
    min-height: 26px;
    min-width: 82px;
    padding: 5px 15.5px;
  }
}

