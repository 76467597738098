@import url(../../../vendor/fonts.css);

.portfolio__content {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 70px 125px 70px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.portfolio__title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 30px;
  color: #A0A0A0;
}

.portfolio__link {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 28px;
  line-height: 50px;
  padding-top: 20px;
  color: black;
}

.portfolio__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.portfolio__column {
  display: flex;
  flex-direction: column;
  min-width: 550px;
}

.portfolio__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 19px;
}

.portfolio__container:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media screen and (max-width: 770px) {
  .portfolio__content {
    padding: 0px 50px 90px 50px;
  }
}

@media screen and (max-width: 650px) {
  .portfolio__link {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 650px) {
  .portfolio__content {
    padding: 0px 14px 70px 14px;
  }
}

@media screen and (max-width: 500px) {
  .portfolio__title {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 40px;
  }
}