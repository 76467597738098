@import url(../../../vendor/fonts.css);

.savedMoviesCardList__content {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  align-items: center;
  padding: 70px 70px 80px 70px;
  margin: 0 auto;
}

@media screen and (max-width: 770px) {
  .savedMoviesCardList__content {
    padding: 50px 30px 50px 30px;
  }
}

@media screen and (max-width: 400px) {
  .savedMoviesCardList__content {
    padding: 50px 10px 50px 10px;
  }
}

.savedMoviesCardList__container {
  max-width: 1280px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 339px);
  grid-template-rows: repeat(auto-fit, 258px);
  gap: 24px;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.savedMoviesCardList__button {
  background-color: #E8E8E8;
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  width: 320px;
  height: 36px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  border: #F4F4F4;
  margin-top: 50px;
}

@media screen and (max-width: 500px) {
  .savedMoviesCardList__button {
    width: 240px;
  }
}

@media screen and (max-width: 1280px) {
  .savedMoviesCardList__container {
    max-width: calc(100vw - 60px);
    justify-items: center;
  }
}

@media screen and (max-width: 770px) {
  .savedMoviesCardList__container {
    grid-template-columns: repeat(auto-fit, 339px);
    grid-template-rows: repeat(auto-fit, 258px);
    gap: 30px;
  }
}

@media screen and (max-width: 400px) {
  .savedMoviesCardList__container {
    grid-template-columns: 300px;
    grid-template-rows: 248px;
    gap: 16px;
  }
}

input[type="checkbox"] + span {
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  background: url(../../../images/smalltumb.svg) no-repeat;
  cursor: pointer;
 }

.savedMoviesCardList__text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin: 0;
}