@import url(../../vendor/fonts.css);

.login__backdround {
  display: flex;
  width: 100%;
  height: calc(100% + 270px);
  position: absolute;
  top: -119px;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  transition: visibility, 900ms ease;
}

.login__button {
  width: 396px;
  height: 45px;
  color: rgb(255, 255, 255);
  background: #3456F3;
  border-radius: 3px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  flex-shrink: 0;
  margin-top: 211px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  align-self: center;
}

.login__button-disabled {
  background: #98a8ec;
}

.login__button-active:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.5s;
}

.login__input {
  background: white;
  border: none;
  border-bottom: 1px solid #E8E8E8;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 396px;
  height: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.login__input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.login__input:focus {
  outline: none;
}

.login__container {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  position: inherit;
  max-width: 396px;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 70px;
}

.login__logo {
  width: 38px;
  height: 38px;
}

.login__title {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  color: rgb(0, 0, 0);
  margin: 0;
  padding: 40px 0;
  text-align: center;
  align-self: flex-start;
} 

.login__form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: inherit;
}

.login__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #A0A0A0;
  margin: 0;
  padding-bottom: 10px;
}

.login__input-error {
  margin-bottom: 14px;
  color: #FF004D;
}

.login__input-error:last-of-type {
  margin-bottom: 0;
}

.login__underbottom-text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A0A0A0;
  margin: 0;
  padding-bottom: 70px;
}


.login__link {
  color: #3456F3;
  text-decoration: none;
}

.login__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.login__link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 16px;
  gap: 6px;
}

@media screen and (max-width: 770px) {
  .login__container {
    padding-top: 232px;
  }
}

@media screen and (max-width: 770px) {
  .login__underbottom-text {
    padding-bottom: 232px;
  }
}

@media screen and (max-width: 500px) {
  .login__container {
    min-width: 282px;
    min-height: 322px;
    padding: 56px 30px 40px 30px;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .login__title {
    padding: 50px 0 80px 0;
    align-self: center;
  }
}

@media screen and (max-width: 500px) {
  .login__input {
    max-width: 260px;
  }
}

@media screen and (max-width: 770px) {
  .login__button {
    margin-top: 211px;
  }
}

@media screen and (max-width: 500px) {
  .login__button {
    width: 260px;
    height: 45px;
    margin-top: 299px;
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 500px) {
  .login__link-container {
    padding-top: 14px;
  }
}

@media screen and (max-width: 500px) {
  .login__underbottom-text {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 30px;
  }
}

.login__text-error {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #A0A0A0;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .login__text-error {
    font-size: 12px;
    line-height: 15px;
  }
}