@import url(../../../vendor/fonts.css);

.aboutProject__content {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 110px 70px 110px 70px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.aboutProject__main-title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
  padding-bottom: 23px;
}

.aboutProject__title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 22px;
}

.aboutProject__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.aboutProject__grid-text {
  font-size: 12px;
  line-height: 17px;
  padding: 9px 0 9px 0;
}

.aboutProject__grid-text:nth-child(3) {
  padding: 14px 0 0 0;
  color: #A0A0A0;
}

.aboutProject__grid-text:nth-child(4) {
  padding: 14px 0 0 0;
  color: #A0A0A0;
}

.aboutProject__column {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.aboutProject__text-block {
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  border-top: 1px solid black;
  justify-content: space-between;
  gap: 30px;
}

.aboutProject__grid-line {
  min-width: 1140px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: repeat(2, 36px);
  text-align: center;
  padding-top: 110px;
  justify-content: center;
  align-self: center;
}

@media screen and (max-width: 1280px) {
  .aboutProject__grid-line  {
    min-width: calc(100vw - 140px);
  }
}

@media screen and (max-width: 770px) {
  .aboutProject__grid-line  {
    grid-template-columns: 140px 528px;
  }
}

.aboutProject__blue-line {
  background-color: #3456F3;
  color: white;
}

.aboutProject__grey-line {
  background-color: #F2F2F2;
}

@media screen and (max-width: 770px) {
  .aboutProject__content {
    padding: 90px 50px 90px 50px;
  }
}

@media screen and (max-width: 770px) {
  .aboutProject__title {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 770px) {
  .aboutProject__text {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 650px) {
  .aboutProject__grid-text {
    font-size: 11px;
    line-height: 13px;
    padding: 11px 0 11px 0;
  }
}

@media screen and (max-width: 650px) {
  .aboutProject__text-block {
    flex-direction: column;
    gap: 56px;
    padding-top: 60px;
  }
}

@media screen and (max-width: 650px) {
  .aboutProject__content {
    padding: 70px 18px 70px 18px;
  }
}

@media screen and (max-width: 650px) {
  .aboutProject__text {
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (max-width: 650px) {
  .aboutProject__title {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (max-width: 770px) {
  .aboutProject__grid-line {
    grid-template-columns: 140px minmax(184px, 528px);
    max-width: calc(100vw - 100px);
  }
}

@media screen and (max-width: 500px) {
  .aboutProject__grid-line {
    padding-top: 60px;
    grid-template-columns: 100px minmax(184px, 528px);
    max-width: calc(100vw - 36px);
  }
}
