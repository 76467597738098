@import url(../../vendor/fonts.css);

.footer__content {
  justify-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 79px 70px 20px 70px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 770px) {
  .footer__content {
    padding: 79px 30px 20px 30px;
    max-width: 708px;
  }
}

@media screen and (max-width: 500px) {
  .footer__content {
    padding: 79px 10px 20px 10px;
  }
}

.footer__title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding-bottom: 20px;
  color: #A0A0A0;
  text-align: center;
  align-self: center;
}

.footer__text {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  padding-top: 20px;
  color: black;
}

.footer__link {
  text-decoration: none;
}

.footer__link:last-child {
  border-bottom: none;
}

.footer__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

.footer__row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footer__text-block {
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  align-items: stretch;
  border-top: 1px solid #E8E8E8;
  justify-content: space-between;
}

@media screen and (max-width: 420px) {
  .footer__row {
    flex-direction: column;
    align-items: center;
    gap: 0;
    max-height: 66px;
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 420px) {
  .footer__text-block {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 10px;
  }
}

@media screen and (max-width: 420px) {
  .footer__title {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 420px) {
  .footer__text {
    font-size: 12px;
    line-height: 15px;
    padding-top: 12px;
  }
}

@media screen and (max-width: 420px) {
  .footer__row {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  .footer__text:first-child {
    padding-top: 20px;
  }
}
